<template>
  <div class="managingPeople hp100 bbox">
    <!-- 卡片视图 -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style lang="less" scoped></style>
